<template>
  <page-section
      subtitle="wsu_homepage.main.subtitle"
      doubleSubtitlePadding
      full-width
  >

    <template #title>
      <h1
          style="max-width: 1000px; margin-left: auto; margin-right: auto;"
          class="text-center"
          :class="[{'px-3' : SM}]"
          :style="!SM ? `font-size: 42px; line-height: 1.2; color : ${wsDARKER}` : `font-size: 24px; line-height: 1.5; color : ${wsDARKER}`"
      >
        {{ $t('wsu_homepage.main.title') }}
      </h1>
    </template>

    <div class="d-flex justify-center mx-auto mt-10">
      <ws-text-field width="375" placeholder="company@name.com" />
      <v-btn
          style="border-bottom-left-radius: 0; border-top-left-radius: 0"
          :to="localeLink('register')"
          :color="wsATTENTION" height="40" elevation="0" dark class="noCaps ml-n1"> {{ $t('Register')}}</v-btn>
    </div>



  </page-section>
</template>

<script>
export default {
  name: "homeSectionMain",
  data() {
    return {
      sliderItems : [
        { text : "features.courseEditor.title",
          value : 'course_editor',
          asset : 'img/dashboard_screens/main_slide_1.png'
        },
        // { text : "features.websiteEditor.title",
        //   value : 'website_editor',
        //   content : 'https://westudy.ua/img/screen-1.b86947ed.jpg'
        // },
        { text : "features.studentManagement.title",
          value : 'student_management',
          asset : 'img/dashboard_screens/main_slide_2.png'
        },
        { text : "features.newsletter.title",
          value : 'newsletter',
          asset : 'img/dashboard_screens/main_slide_3.png'
        },
        { text : "features.chat_bots.title",
          value : 'chat_bots',
          asset : 'img/dashboard_screens/main_slide_4.png'
        },
        { text : "features.crm.title",
          value : 'crm',
          asset : 'img/dashboard_screens/main_slide_5.png'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>